.login {
  background-color: #1a202c;
  /* background-color: rgb(26, 32, 44); */
}
.login-container {
  justify-content: space-evenly;
  width: 80%;
}

.loginwrap {
  border-radius: 0 !important;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}
.login-container h1 {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.4em;
}
/* @media only screen and (max-width: 1200px) {
  .login-container h1 {
    background-color: lightblue;
  }
} */
.registration-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.registration-form > input {
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-form > input {
  margin-bottom: 10px;
}
